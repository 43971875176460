import React, { Component } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Pie } from '@nivo/pie';
import colores from '../../../css/bootstrap.scss';
import {MyContext} from '../../../context/AuthContext';
import * as XLSX from 'xlsx/xlsx.mjs';
import { parseISO, lastDayOfMonth } from 'date-fns';
import { MoonLoader } from 'react-spinners';
import SwitchSelector from 'react-switch-selector';

class Anual extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ano: new Date().getFullYear(),
            data: [
                {mes: 'Enero'},
                {mes: 'Febrero'},
                {mes: 'Marzo'},
                {mes: 'Abril'},
                {mes: 'Mayo'},
                {mes: 'Junio'},
                {mes: 'Julio'},
                {mes: 'Agosto'},
                {mes: 'Septiembre'},
                {mes: 'Octubre'},
                {mes: 'Noviembre'},
                {mes: 'Diciembre'},
            ],
            saldos: [],
            resumen: [],
            dataActualizada: false,
            cargando: true,
            vista: 'numeros'
        }
    }
    
    static contextType = MyContext;

    componentDidMount = async () => {
        this.selectResumen();
        this.selectAnual();
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.sucursal !== prevProps.sucursal) {
            this.selectResumen();
            this.selectAnual();
        }
    }

    formatDate = (fecha) => {
        var fechaFormateada = fecha.split('/');
        fechaFormateada[0] = Number(fechaFormateada[0]).toLocaleString('es-MX', { minimumIntegerDigits: 2 });
        fechaFormateada[1] = Number(fechaFormateada[1]).toLocaleString('es-MX', { minimumIntegerDigits: 2 });
        return fechaFormateada.reverse().join('-')
    }

    selectResumen = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectAnualResumen', ano: this.state.ano, sucursal: this.props.sucursal, empresa: this.context.rootState.nombre })
        };
        const response = await fetch('http://pruebas.argeos.net/php/DB/registros.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            this.setState({
                resumen: data
            })
        }else{
            toast.error('Error al obtener resumen anual');
            console.log(data.mensaje);
        }
    }

    selectAnual = async () => {
        this.setState({
            cargando: true
        })
        this.selectSaldo();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectAnual', ano: this.state.ano, sucursal: this.props.sucursal, empresa: this.context.rootState.nombre })
        };
        const response = await fetch('http://pruebas.argeos.net/php/DB/registros.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            let nuevaData = this.state.data;
            nuevaData.forEach( async (element, index) => {
                let ingresos = data.ingresos.find(e => e.mes === element.mes);
                if (ingresos) {
                    nuevaData[index].ingresos = Number(ingresos.monto);
                }else{
                    nuevaData[index].ingresos = 0;
                }
                let costosventa = data.costosventa.find(e => e.mes === element.mes);
                if (costosventa) {
                    nuevaData[index].costosventa = -Number(costosventa.monto);
                }else{
                    nuevaData[index].costosventa = 0;
                }
                let gastosoperativos = data.gastosoperativos.find(e => e.mes === element.mes);
                if (gastosoperativos) {
                    nuevaData[index].gastosoperativos = -Number(gastosoperativos.monto);
                }else{
                    nuevaData[index].gastosoperativos = 0;
                }
                let repartoutilidades = data.repartoutilidades.find(e => e.mes === element.mes);
                if (repartoutilidades) {
                    nuevaData[index].repartoutilidades = -Number(repartoutilidades.monto);
                }else{
                    nuevaData[index].repartoutilidades = 0;
                }
                let inversiones = data.inversiones.find(e => e.mes === element.mes);
                if (inversiones) {
                    nuevaData[index].inversiones = -Number(inversiones.monto);
                }else{
                    nuevaData[index].inversiones = 0;
                }
            });
            this.setState({
                data: nuevaData,
                dataActualizada: true,
            })
        }else{
            toast.error('Error al obtener la información.');
            console.log(data.mensaje);
        }
    }

    selectSaldo = async () => {
        let fechas = [];
        this.state.data.forEach((_, index) => {
            fechas.push(this.formatDate(lastDayOfMonth(parseISO(this.state.ano + '-' + (index + 1).toLocaleString('es-MX', { minimumIntegerDigits: 2, useGrouping: false }))).toLocaleDateString()));
        });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectSaldosDias', fechas: fechas, empresa: this.context.rootState.nombre })
        };
        const response = await fetch('http://pruebas.argeos.net/php/DB/registros.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            this.setState({
                saldos: data.data,
                cargando: false
            })
        }else{
            toast.error('Error al cargar los datos');
            console.log(data.mensaje);
        }
    }

    getHeightWindow = () => {
        var height = window.innerHeight;
        return height - 70
    }

    numberWithCommas = (x) => {
        x = Number(x).toFixed(2);
        if (x === 0) {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    getUtilidades = (ingresos, costosventa, gastosoperativos) => {
        let utilidades = ingresos - (Math.abs(costosventa) + Math.abs(gastosoperativos))
        return utilidades
    }
    
    getBalance = (ingresos, costosventa, gastosoperativos, repartoutilidades, inversiones) => {
        let disponible = ingresos - (Math.abs(costosventa) + Math.abs(gastosoperativos) + Math.abs(repartoutilidades) + Math.abs(inversiones))
        return disponible
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    cambiarVista = (newValue) => {
        this.setState({
            vista: newValue
        })
    }

    buscar = () => {
        this.selectResumen();
        this.selectAnual();
    }

    ExportToExcel(type, fn, dl) {
        var elt = document.getElementById('tbl_exporttable_to_xls');
        var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet1" });
        return dl ?
        XLSX.write(wb, { bookType: type, bookSST: true, type: 'base64' }):
        XLSX.writeFile(wb, fn || ('Resumen Anual ' + this.state.ano + '.' + (type || 'xlsx')));
     }

    getPorcentaje = (total, parte) => {
        total = Number(total);
        parte = Math.abs(Number(parte));
        if (total !== 0 && parte !== 0) {
            return ((parte/total)*100).toFixed(0)
        }
        return 0
    }
    
    getPorcentajeTotal = (total, parte) => {
        total = Number(total);
        parte = Number(parte);
        if (total !== 0 && parte !== 0) {
            return ((parte/total)*100).toFixed(0)
        }
        return 0
    }

    CenteredMetric = ({ centerX, centerY }) => {
        let total = 0
        total = this.getBalance(this.state.resumen.ingresos, this.state.resumen.costosventa, this.state.resumen.gastosoperativos, this.state.resumen.repartoutilidades, this.state.resumen.inversiones);
        return (
            <>                
                <text
                    x={centerX}
                    y={centerY - 15}
                    textAnchor="middle"
                    dominantBaseline="central"
                    style={{
                        fontSize: '24px',
                        fontWeight: 600,
                    }}
                >
                    Balance
                </text>
                <text
                    x={centerX}
                    y={centerY + 15}
                    textAnchor="middle"
                    dominantBaseline="central"
                    style={{
                        fontSize: '24px',
                        fontWeight: 600,
                    }}
                >
                    ${this.numberWithCommas(total)}
                </text>
            </>
        )
    }
    
    render() { 
        return ( 
            <>
            <div className={"col-4 py-0 px-1 " + (this.props.sucursal ? 'mt-2' : '')}>
                <div className="inputGroup">
                    <label htmlFor="ano">Año</label>
                    <input type="number" id='ano' step={1} className='form-control inputUnderline text-center text-white' style={{ borderRadius: '0px', fontSize: '16px' }} value={this.state.ano} name="ano" onChange={this.onChangeValue}/>
                </div>
            </div>
            <div className="col-4 mt-2 py-0 px-1">
                <button className='w-100 h-100 btnAplicar' onClick={this.buscar}>Aplicar</button>
            </div>
            {
                this.state.cargando ? 
                    <div className='d-flex justify-content-center mt-5'>
                        <div className="text-start">
                            <MoonLoader color={colores.primary} size={200}/>
                        </div>
                    </div>
                :
                    <div className='contenido px-3'>
                        <div className="mt-3 mx-auto" style={{ height: '40px', width: '200px' }}>
                            <SwitchSelector
                                forcedSelectedIndex={[{label: "Datos", value: "numeros"},{label: "Gráficos", value: "graficos"}].findIndex(e => e.value === this.state.vista)}
                                onChange={this.cambiarVista}
                                wrapperBorderRadius={5}
                                optionBorderRadius={5}
                                backgroundColor={colores.primary}
                                selectedBackgroundColor={colores.dark}
                                options={[
                                    {label: <i className="bi bi-table text-white"></i>, value: "numeros"},
                                    {label: <i className="bi bi-pie-chart text-white"></i>, value: "graficos"}
                                ]}
                            />
                        </div>
                        {
                            this.state.vista === 'numeros' ?
                                <>
                                    <div className="elemento mt-4 mb-3">
                                        <h6 className='m-0 text-center'>Por Mes</h6>
                                    </div>
                                    <div className="" style={{ overflowX: 'auto' }}>
                                        <table id='tbl_exporttable_to_xls'>
                                            <thead>
                                                <tr>
                                                    <th className='thSubheader stickyTH'></th>
                                                    <th className='thSubheader'>Enero</th>
                                                    <th className='thSubheader'>Febrero</th>
                                                    <th className='thSubheader'>Marzo</th>
                                                    <th className='thSubheader'>Abril</th>
                                                    <th className='thSubheader'>Mayo</th>
                                                    <th className='thSubheader'>Junio</th>
                                                    <th className='thSubheader'>Julio</th>
                                                    <th className='thSubheader'>Agosto</th>
                                                    <th className='thSubheader'>Septiembre</th>
                                                    <th className='thSubheader'>Octubre</th>
                                                    <th className='thSubheader'>Noviembre</th>
                                                    <th className='thSubheader'>Diciembre</th>
                                                    <th className='thSubheader' colSpan={2}>Resumen</th>
                                                    <th className='thSubheader'>Prom. Mes</th>
                                                </tr>
                                            </thead>
                                                {
                                                    this.state.dataActualizada ?
                                                        <tbody>
                                                            <tr className='text-end'>
                                                                <td className='tdConcepto sticky'>
                                                                    <div className="input-group">
                                                                        <div className="input-group-prepend">
                                                                            <span className="input-group-text" style={{ backgroundColor: '#D1FFE3' }}><i className="bi bi-cash-coin"></i></span>
                                                                        </div>
                                                                        <input type="text" className="form-control d-flex my-auto ms-1" value={"Ingresos"} readOnly/>
                                                                    </div>
                                                                </td>
                                                                {
                                                                    this.state.data.map((e) =>
                                                                    [
                                                                        <td key={1}>$ {this.numberWithCommas(e.ingresos)}</td>,
                                                                    ]
                                                                    )
                                                                }                                            
                                                                <td>$ {this.numberWithCommas(this.state.resumen.ingresos)}</td>
                                                                <td>{this.getPorcentaje(this.state.resumen.ingresos, this.state.resumen.ingresos)}%</td>
                                                                <td>$ {this.numberWithCommas((this.state.resumen.ingresos/12))}</td>
                                                            </tr>
                                                            <tr className='text-end'>
                                                                <td className='tdConcepto sticky'>
                                                                    <div className="input-group">
                                                                        <div className="input-group-prepend">
                                                                            <span className="input-group-text" style={{ backgroundColor: '#FEF6FF' }}><i className="bi bi-wallet2"></i></span>
                                                                        </div>
                                                                        <input type="text" className="form-control d-flex my-auto ms-1" value={"Costo de venta"} readOnly/>
                                                                    </div>
                                                                </td>
                                                                {
                                                                    this.state.data.map((e) =>
                                                                    [
                                                                        <td key={1}>$ {this.numberWithCommas(Math.abs(e.costosventa))}</td>,
                                                                    ]
                                                                    )
                                                                }
                                                                <td>$ {this.numberWithCommas(this.state.resumen.costosventa)}</td>
                                                                <td>{this.getPorcentaje(this.state.resumen.ingresos, this.state.resumen.costosventa)}%</td>
                                                                <td>$ {this.numberWithCommas((this.state.resumen.costosventa/12))}</td>
                                                            </tr>
                                                            <tr className='text-end'>
                                                                <td className='tdConcepto sticky'>
                                                                    <div className="input-group">
                                                                        <div className="input-group-prepend">
                                                                            <span className="input-group-text" style={{ backgroundColor: '#FFFADE' }}><i className="bi bi-droplet"></i></span>
                                                                        </div>
                                                                        <input type="text" className="form-control d-flex my-auto ms-1" value={"Gastos operativos"} readOnly/>
                                                                    </div>
                                                                </td>
                                                                {
                                                                    this.state.data.map((e) =>
                                                                    [
                                                                        <td key={1}>$ {this.numberWithCommas(Math.abs(e.gastosoperativos))}</td>,
                                                                    ]
                                                                    )
                                                                }
                                                                <td>$ {this.numberWithCommas(this.state.resumen.gastosoperativos)}</td>
                                                                <td>{this.getPorcentaje(this.state.resumen.ingresos, this.state.resumen.gastosoperativos)}%</td>
                                                                <td>$ {this.numberWithCommas((this.state.resumen.gastosoperativos/12))}</td>
                                                            </tr>
                                                            <tr style={{ fontWeight: '400' }} className='text-end'>
                                                                <td className='tdConcepto sticky'>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" style={{ backgroundColor: '#eaeaea' }}><i className="bi bi-cash"></i></span>
                                                                </div>
                                                                <input type="text" className="form-control d-flex my-auto ms-1" value={"Utilidades"} readOnly/>
                                                            </div>
                                                        </td>
                                                                {
                                                                    this.state.data.map((e) =>
                                                                    [
                                                                        <td key={1}>$ {this.numberWithCommas(this.getUtilidades(e.ingresos, e.costosventa, e.gastosoperativos))}</td>,
                                                                    ]
                                                                    )
                                                                }
                                                                <td>$ {this.numberWithCommas(this.getUtilidades(this.state.resumen.ingresos, this.state.resumen.costosventa, this.state.resumen.gastosoperativos))}</td>
                                                                <td>{this.getPorcentaje(this.state.resumen.ingresos, this.getUtilidades(this.state.resumen.ingresos, this.state.resumen.costosventa, this.state.resumen.gastosoperativos))}%</td>
                                                                <td>$ {this.numberWithCommas(this.getUtilidades((this.state.resumen.ingresos/12), (this.state.resumen.costosventa/12), (this.state.resumen.gastosoperativos/12)))}</td>
                                                            </tr>
                                                            <tr className='text-end'>
                                                                <td className='tdConcepto sticky'>
                                                                    <div className="input-group">
                                                                        <div className="input-group-prepend">
                                                                            <span className="input-group-text" style={{ backgroundColor: '#D3E5EF' }}><i className="bi bi-cash-stack"></i></span>
                                                                        </div>
                                                                        <input type="text" className="form-control d-flex my-auto ms-1" value={"Reparto utilidades"} readOnly/>
                                                                    </div>
                                                                </td>
                                                                {
                                                                    this.state.data.map((e) =>
                                                                    [
                                                                        <td key={1}>$ {this.numberWithCommas(Math.abs(e.repartoutilidades))}</td>,
                                                                    ]
                                                                    )
                                                                }
                                                                <td>$ {this.numberWithCommas(this.state.resumen.repartoutilidades)}</td>
                                                                <td>{this.getPorcentaje(this.state.resumen.ingresos, this.state.resumen.repartoutilidades)}%</td>
                                                                <td>$ {this.numberWithCommas((this.state.resumen.repartoutilidades/12))}</td>
                                                            </tr>
                                                            <tr className='text-end'>
                                                                <td className='tdConcepto sticky'>
                                                                    <div className="input-group">
                                                                        <div className="input-group-prepend">
                                                                            <span className="input-group-text" style={{ backgroundColor: '#f9d4ea' }}><i className="bi bi-graph-up-arrow"></i></span>
                                                                        </div>
                                                                        <input type="text" className="form-control d-flex my-auto ms-1" value={"Inversiones"} readOnly/>
                                                                    </div>
                                                                </td>
                                                                {
                                                                    this.state.data.map((e) =>
                                                                    [
                                                                        <td key={1}>$ {this.numberWithCommas(Math.abs(e.inversiones))}</td>,
                                                                    ]
                                                                    )
                                                                }
                                                                <td>$ {this.numberWithCommas(this.state.resumen.inversiones)}</td>
                                                                <td>{this.getPorcentaje(this.state.resumen.ingresos, this.state.resumen.inversiones)}%</td>
                                                                <td>$ {this.numberWithCommas((this.state.resumen.inversiones/12))}</td>
                                                            </tr>
                                                            <tr className='text-end'>
                                                                <td className='tdConcepto sticky'>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" style={{ backgroundColor: '#eaeaea' }}><i className="bi bi-graph-up"></i></span>
                                                                </div>
                                                                <input type="text" className="form-control d-flex my-auto ms-1" value={"Balance"} readOnly/>
                                                            </div>
                                                        </td>
                                                                {
                                                                    this.state.data.map((e) =>
                                                                    [
                                                                        <td key={1}>$ {this.numberWithCommas(this.getBalance(e.ingresos, e.costosventa, e.gastosoperativos, e.repartoutilidades, e.inversiones))}</td>,
                                                                    ]
                                                                    )
                                                                }
                                                                <td>$ {this.numberWithCommas(this.getBalance(this.state.resumen.ingresos, this.state.resumen.costosventa, this.state.resumen.gastosoperativos, this.state.resumen.repartoutilidades, this.state.resumen.inversiones))}</td>
                                                                <td>{this.getPorcentajeTotal(this.state.resumen.ingresos, this.getBalance(this.state.resumen.ingresos, this.state.resumen.costosventa, this.state.resumen.gastosoperativos, this.state.resumen.repartoutilidades, this.state.resumen.inversiones))}%</td>
                                                                <td>$ {this.numberWithCommas(this.getBalance(this.state.resumen.ingresos, this.state.resumen.costosventa, this.state.resumen.gastosoperativos, this.state.resumen.repartoutilidades, this.state.resumen.inversiones)/12)}</td>
                                                            </tr>
                                                            <tr className='text-end'>
                                                                <td className='tdConcepto sticky'>
                                                                    <div className="input-group">
                                                                        <div className="input-group-prepend">
                                                                            <span className="input-group-text" style={{ backgroundColor: '#eaeaea' }}><i className="bi bi-bank"></i></span>
                                                                        </div>
                                                                        <input type="text" className="form-control d-flex my-auto ms-1" value={"Disponible"} readOnly/>
                                                                    </div>
                                                                </td>
                                                                {
                                                                    this.state.saldos.map((e) =>
                                                                    [
                                                                        <td key={1}>$ {this.numberWithCommas(e)}</td>,
                                                                    ]
                                                                    )
                                                                }
                                                                <td colSpan={3}></td>
                                                            </tr>
                                                        </tbody>
                                                    :
                                                        <tbody></tbody>
                                                }
                                        </table>
                                    </div>
                                    <button onClick={() => this.ExportToExcel('xlsx')} className='btn mt-1 mb-4' style={{ padding: '0px', textDecoration: 'underline', fontSize: '14px', color: 'gray' }}>Descargar tabla</button>
                                </>
                            :
                                <>
                                    <div className="elemento mt-4 mb-3">
                                        <h6 className='m-0 text-center'>General</h6>
                                    </div>
                                    <div className="elemento py-3 my-4">
                                        <div className="elemento p-0 d-flex mx-auto" style={{ width: 'fit-content', borderRadius: '100%', backgroundColor: 'white', border: '3px solid #654E7C' }}>
                                            <Pie
                                                innerRadius={0.5}
                                                width={320}
                                                height={320}
                                                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                                                colors={{ datum: 'data.color' }}
                                                arcLabel={e => `$${this.numberWithCommas(e.value)}`}
                                                enableArcLinkLabels={false}
                                                tooltip={({ datum: {label, color} }) =>
                                                    <div className="elemento p-1" style={{ border: '2px solid ' + color }}>
                                                        <p className='m-0'>{label}</p>
                                                    </div>
                                                }
                                                layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', this.CenteredMetric]}
                                                data={[
                                                    { id: 'ingresos', label: 'Ingresos', value: this.state.resumen.ingresos, color: '#D1FFE3'},
                                                    { id: 'costosventa', label: 'Costos Venta', value: this.state.resumen.costosventa, color: '#FEF6FF'},
                                                    { id: 'gastosoperativos', label: 'Gastos Operativos', value: this.state.resumen.gastosoperativos, color: '#FFFADE'},
                                                    { id: 'repartoutilidades', label: 'Reparto Utilidades', value: this.state.resumen.repartoutilidades, color: '#D3E5EF'},
                                                    { id: 'inversiones', label: 'Inversiones', value: this.state.resumen.inversiones, color: '#f9d4ea'},
                                                ].filter(e => e.value !== 0)}
                                            />
                                        </div>
                                    </div>
                                </>                            
                        }
                    </div>
            }
            <Toaster/>
            </>
         );
    }
}
 
export default Anual;