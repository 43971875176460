import React, { Component } from 'react';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import toast, { Toaster } from 'react-hot-toast';
import { Pie } from '@nivo/pie';
import {MyContext} from '../../../context/AuthContext';
import { MoonLoader } from 'react-spinners';
import colores from '../../../css/bootstrap.scss';
import SwitchSelector from 'react-switch-selector';

class Diario extends Component {
    constructor(props) {
        super(props);
        this.tablaResumen = React.createRef();
        this.state = {
            fecha: this.formatDate(new Date().toLocaleDateString()),
            data: {
                ingresos: 0,
                costosventa: 0,
                gastosoperativos: 0,
                repartoutilidades: 0,
            },
            saldo: 0,
            cargando: true,
            vista: 'numeros'
        }
    }
    
    static contextType = MyContext;

    formatDate = (fecha) => {
        var fechaFormateada = fecha.split('/');
        fechaFormateada[0] = Number(fechaFormateada[0]).toLocaleString('es-MX', { minimumIntegerDigits: 2 });
        fechaFormateada[1] = Number(fechaFormateada[1]).toLocaleString('es-MX', { minimumIntegerDigits: 2 });
        return fechaFormateada.reverse().join('-')
    }

    componentDidMount = async () => {
        this.select();
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.sucursal !== prevProps.sucursal) {
            this.select();
        }
    }

    select = async () => {
        this.setState({
            cargando: true
        })
        this.selectSaldo();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectFecha', fecha: this.state.fecha, sucursal: this.props.sucursal, empresa: this.context.rootState.nombre })
        };
        const response = await fetch('http://pruebas.argeos.net/php/DB/registros.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            this.setState({
                data: data
            })
        }else{
            toast.error('Error al cargar los datos');
            console.log(data.mensaje);
        }
    }

    selectSaldo = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectSaldoDia', fecha: this.state.fecha, empresa: this.context.rootState.nombre })
        };
        const response = await fetch('http://pruebas.argeos.net/php/DB/registros.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            let total = 0;
            data.data.forEach(element => {
                total = total + Number(element.saldo)
            });
            this.setState({
                saldo: total,
                cargando: false
            })
        }else{
            toast.error('Error al cargar los datos');
            console.log(data.mensaje);
        }
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    cambiarVista = (newValue) => {
        this.setState({
            vista: newValue
        })
    }

    getUtilidades = (ingresos, costosventa, gastosoperativos) => {
        let utilidades = ingresos - (Math.abs(costosventa) + Math.abs(gastosoperativos))
        return utilidades
    }
    
    getBalance = (ingresos, costosventa, gastosoperativos, repartoutilidades, inversiones) => {
        let disponible = ingresos - (Math.abs(costosventa) + Math.abs(gastosoperativos) + Math.abs(repartoutilidades) + Math.abs(inversiones))
        return disponible
    }

    numberWithCommas = (x) => {
        x = Number(x).toFixed(2);
        if (x === 0) {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    buscar = () => {
        this.select();
    }

    getPorcentaje = (total, parte) => {
        total = Number(total);
        parte = Number(parte);
        if (total !== 0 && parte !== 0) {
            return ((parte/total)*100).toFixed(0)
        }
        return 0
    }

    CenteredMetric = ({ centerX, centerY }) => {
        let total = 0
        total = this.getBalance(this.state.data.ingresos, this.state.data.costosventa, this.state.data.gastosoperativos, this.state.data.repartoutilidades, this.state.data.inversiones)
        return (
            <>                
                <text
                    x={centerX}
                    y={centerY - 15}
                    textAnchor="middle"
                    dominantBaseline="central"
                    style={{
                        fontSize: '24px',
                        fontWeight: 600,
                    }}
                >
                    Balance
                </text>
                <text
                    x={centerX}
                    y={centerY + 15}
                    textAnchor="middle"
                    dominantBaseline="central"
                    style={{
                        fontSize: '24px',
                        fontWeight: 600,
                    }}
                >
                    ${this.numberWithCommas(total)}
                </text>
            </>
        )
    }
    
    render() { 
        return ( 
            <>
                <div className={"col-4 py-0 px-1 " + (this.props.sucursal ? 'mt-2' : '')}>
                    <div className="inputGroup">
                        <label htmlFor="fecha">Fecha</label>
                        <input type="date" id="fecha" className='form-control inputUnderline text-center text-white' style={{ borderRadius: '0px', fontSize: '16px' }} name="fecha" value={this.state.fecha} onChange={this.onChangeValue}/>
                    </div>
                </div>
                <div className="col-4 mt-2 py-0 px-2">
                        <button className='w-100 btnAplicar' onClick={this.buscar}>Aplicar</button>
                </div>
                {
                    this.state.cargando ? 
                        <div className='d-flex justify-content-center mt-5'>
                            <div className="text-start">
                                <MoonLoader color={colores.primary} size={200}/>
                            </div>
                        </div>
                    :
                        <div className='contenido px-3'>
                            <div className="mt-3 mx-auto" style={{ height: '40px', width: '200px' }}>
                                <SwitchSelector
                                    forcedSelectedIndex={[{label: "Datos", value: "numeros"},{label: "Gráficos", value: "graficos"}].findIndex(e => e.value === this.state.vista)}
                                    onChange={this.cambiarVista}
                                    wrapperBorderRadius={5}
                                    optionBorderRadius={5}
                                    backgroundColor={colores.primary}
                                    selectedBackgroundColor={colores.dark}
                                    options={[
                                        {label: <i className="bi bi-table text-white"></i>, value: "numeros"},
                                        {label: <i className="bi bi-pie-chart text-white"></i>, value: "graficos"}
                                    ]}
                                />
                            </div>
                            {
                                this.state.vista === 'numeros' ?
                                    <>
                                        <div className="mt-2" style={{ overflowX: 'auto' }}>
                                            <table ref={this.tablaResumen}>
                                                <thead>
                                                    <tr>
                                                        <th className='thSubheader'>Concepto</th>
                                                        <th className='thSubheader'>Monto</th>
                                                        <th className='thSubheader'>%</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className='text-end'>
                                                        <td className='tdConcepto'>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" style={{ backgroundColor: '#D1FFE3' }}><i className="bi bi-cash-coin"></i></span>
                                                                </div>
                                                                <input type="text" className="form-control d-flex my-auto ms-1" value={"Ingresos"} readOnly/>
                                                            </div>
                                                        </td>
                                                        <td>$ {this.numberWithCommas(this.state.data.ingresos)}</td>
                                                        <td>{this.getPorcentaje(this.state.data.ingresos, this.state.data.ingresos)}%</td>
                                                    </tr>
                                                    <tr className='text-end'>
                                                        <td className='tdConcepto'>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" style={{ backgroundColor: '#FEF6FF' }}><i className="bi bi-wallet2"></i></span>
                                                                </div>
                                                                <input type="text" className="form-control d-flex my-auto ms-1" value={"Costo de venta"} readOnly/>
                                                            </div>
                                                        </td>
                                                        <td>$ {this.numberWithCommas(this.state.data.costosventa)}</td>
                                                        <td>{this.getPorcentaje(this.state.data.ingresos, this.state.data.costosventa)}%</td>
                                                    </tr>
                                                    <tr className='text-end'>
                                                        <td className='tdConcepto'>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" style={{ backgroundColor: '#FFFADE' }}><i className="bi bi-droplet"></i></span>
                                                                </div>
                                                                <input type="text" className="form-control d-flex my-auto ms-1" value={"Gastos operativos"} readOnly/>
                                                            </div>
                                                        </td>
                                                        <td>$ {this.numberWithCommas(this.state.data.gastosoperativos)}</td>
                                                        <td>{this.getPorcentaje(this.state.data.ingresos, this.state.data.gastosoperativos)}%</td>
                                                    </tr>
                                                    <tr style={{ fontWeight: '400' }} className='text-end'>
                                                        <td className='tdConcepto'>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" style={{ backgroundColor: '#eaeaea' }}><i className="bi bi-cash"></i></span>
                                                                </div>
                                                                <input type="text" className="form-control d-flex my-auto ms-1" value={"Utilidades"} readOnly/>
                                                            </div>
                                                        </td>
                                                        <td>$ {this.numberWithCommas(this.getUtilidades(this.state.data.ingresos, this.state.data.costosventa, this.state.data.gastosoperativos))}</td>
                                                        <td>{this.getPorcentaje(this.state.data.ingresos, this.getUtilidades(this.state.data.ingresos, this.state.data.costosventa, this.state.data.gastosoperativos))}%</td>
                                                    </tr>
                                                    <tr className='text-end'>
                                                        <td className='tdConcepto'>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" style={{ backgroundColor: '#D3E5EF' }}><i className="bi bi-cash-stack"></i></span>
                                                                </div>
                                                                <input type="text" className="form-control d-flex my-auto ms-1" value={"Reparto utilidades"} readOnly/>
                                                            </div>
                                                        </td>
                                                        <td>$ {this.numberWithCommas(this.state.data.repartoutilidades)}</td>
                                                        <td>{this.getPorcentaje(this.state.data.ingresos, this.state.data.repartoutilidades)}%</td>
                                                    </tr>
                                                    <tr className='text-end'>
                                                        <td className='tdConcepto'>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" style={{ backgroundColor: '#f9d4ea' }}><i className="bi bi-graph-up-arrow"></i></span>
                                                                </div>
                                                                <input type="text" className="form-control d-flex my-auto ms-1" value={"Inversiones"} readOnly/>
                                                            </div>
                                                        </td>
                                                        <td>$ {this.numberWithCommas(this.state.data.inversiones)}</td>
                                                        <td>{this.getPorcentaje(this.state.data.ingresos, this.state.data.inversiones)}%</td>
                                                    </tr>
                                                    <tr style={{ fontWeight: '400' }} className='text-end'>
                                                        <td className='tdConcepto'>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" style={{ backgroundColor: '#eaeaea' }}><i className="bi bi-graph-up"></i></span>
                                                                </div>
                                                                <input type="text" className="form-control d-flex my-auto ms-1" value={"Balance"} readOnly/>
                                                            </div>
                                                        </td>
                                                        <td>$ {this.numberWithCommas(this.getBalance(this.state.data.ingresos, this.state.data.costosventa, this.state.data.gastosoperativos, this.state.data.repartoutilidades, this.state.data.inversiones))}</td>
                                                        <td>{this.getPorcentaje(this.state.data.ingresos, this.getBalance(this.state.data.ingresos, this.state.data.costosventa, this.state.data.gastosoperativos, this.state.data.repartoutilidades, this.state.data.inversiones))}%</td>
                                                    </tr>
                                                    <tr style={{ fontWeight: '400' }} className='text-end'>
                                                        <td className='tdConcepto'>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" style={{ backgroundColor: '#eaeaea' }}><i className="bi bi-bank"></i></span>
                                                                </div>
                                                                <input type="text" className="form-control d-flex my-auto ms-1" value={"Disponible"} readOnly/>
                                                            </div>
                                                        </td>
                                                        <td colSpan={2}>$ {this.numberWithCommas(this.state.saldo)}</td>
                                                    </tr>                                        
                                                </tbody>
                                            </table>
                                        </div>
                                        <div>
                                            <DownloadTableExcel
                                                filename="Resumen Anual"
                                                sheet="Resumen Anual"
                                                currentTableRef={this.tablaResumen.current}
                                                >
                                            <button className='btn mt-1 mb-4' style={{ padding: '0px', textDecoration: 'underline', fontSize: '14px', color: 'gray' }}>Descargar tabla</button>
                                            </DownloadTableExcel>
                                        </div>
                                    </>
                                :
                                    <div className="elemento py-3 my-4">
                                        <div className="elemento p-0 d-flex mx-auto" style={{ width: 'fit-content', borderRadius: '100%', backgroundColor: 'white', border: '3px solid #654E7C' }}>
                                            <Pie
                                                innerRadius={0.5}
                                                width={320}
                                                height={320}
                                                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                                                colors={{ datum: 'data.color' }}
                                                arcLabel={e => `$${this.numberWithCommas(e.value)}`}
                                                enableArcLinkLabels={false}
                                                tooltip={({ datum: {label, color} }) =>
                                                    <div className="elemento p-1" style={{ border: '2px solid ' + color }}>
                                                        <p className='m-0'>{label}</p>
                                                    </div>
                                                }
                                                layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', this.CenteredMetric]}
                                                data={[
                                                    { id: 'ingresos', label: 'Ingresos', value: this.state.data.ingresos, color: '#D1FFE3'},
                                                    { id: 'costosVenta', label: 'Costos Venta', value: this.state.data.costosventa, color: '#FEF6FF'},
                                                    { id: 'gastosOperativos', label: 'Gastos Operativos', value: this.state.data.gastosoperativos, color: '#FFFADE'},
                                                    { id: 'repartoUtilidades', label: 'Reparto Utilidades', value: this.state.data.repartoutilidades, color: '#D3E5EF'},
                                                    { id: 'inversiones', label: 'Inversiones', value: this.state.data.inversiones, color: '#f9d4ea'},
                                                ].filter(e => e.value !== 0)}
                                            />
                                        </div>
                                    </div>
                            }
                        </div>
                }
                <Toaster/>
            </>
         );
    }
}
 
export default Diario;