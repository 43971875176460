import React, { Component } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Pie } from '@nivo/pie';
import colores from '../../../css/bootstrap.scss';
import {MyContext} from '../../../context/AuthContext';
import * as XLSX from 'xlsx/xlsx.mjs';
import { MoonLoader } from 'react-spinners';
import SwitchSelector from 'react-switch-selector';

class AnualGO extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ano: new Date().getFullYear(),
            showFullScreen: false,
            data: [
                {mes: 'Enero'},
                {mes: 'Febrero'},
                {mes: 'Marzo'},
                {mes: 'Abril'},
                {mes: 'Mayo'},
                {mes: 'Junio'},
                {mes: 'Julio'},
                {mes: 'Agosto'},
                {mes: 'Septiembre'},
                {mes: 'Octubre'},
                {mes: 'Noviembre'},
                {mes: 'Diciembre'},
            ],
            resumen: [],
            dataGeneral: [],
            dataActualizada: false,
            cargando: true,
            vista: 'numeros'
        }
    }
    
    static contextType = MyContext;

    componentDidMount = async () => {
        this.selectResumen();
        this.selectAnual();
    }

    selectResumen = async () => {
        this.setState({
            cargando: true
        })
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectAnualResumenGO', ano: this.state.ano, empresa: this.context.rootState.nombre })
        };
        const response = await fetch('http://pruebas.argeos.net/php/DB/registros.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            this.setState({
                resumen: data.data
            })
        }else{
            toast.error('Error al obtener resumen anual');
            console.log(data.mensaje);
        }
    }

    selectAnual = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectAnualGO', ano: this.state.ano, empresa: this.context.rootState.nombre })
        };
        const response = await fetch('http://pruebas.argeos.net/php/DB/registros.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            this.setState({
                data: data.servicios,
                dataGeneral: data.dataGeneral,
                dataActualizada: true,
                cargando: false
            })
        }else{
            toast.error('Error al obtener la información.');
            console.log(data.mensaje);
        }
    }

    getHeightWindow = () => {
        var height = window.innerHeight;
        return height - 70
    }

    numberWithCommas = (x) => {
        x = Number(x).toFixed(2);
        if (x === 0) {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    showFullScreen = () => {
        this.setState({
            showFullScreen: true
        })
    }

    hideFullScreen = () => {
        this.setState({
            showFullScreen: false
        })
    }

    commonProps = {
        width: 1000,
        height: 400,
        margin: { top: 30, right: 80, bottom: 30, left: 80 },
        indexBy: 'mes',
        labelSkipWidth: 16,
        labelSkipHeight: 16,
        enableGridX: true,
        enableGridY: false,
        labelTextColor: 'white',
        axisTop: {
            tickSize: 0,
            tickPadding: 12,
        },
        axisBottom: {
            legend: 'Meses',
            legendPosition: 'middle',
            legendOffset: 50,
            tickSize: 0,
            tickPadding: 12,
        },
        axisLeft: {
            format: (v) => `$ ${(this.numberWithCommas(v))}`,
        },
        axisRight: {
            format: (v) => `$ ${(this.numberWithCommas(v))}`,
        },
        markers: [
            {
                axis: 'y',
                value: 0,
                lineStyle: { stroke: colores.dark, strokeWidth: 1 },
                textStyle: { fill: colores.dark },
            },
        ],
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    cambiarVista = (newValue) => {
        this.setState({
            vista: newValue
        })
    }

    buscar = () => {
        this.selectResumen();
        this.selectAnual();
    }

    ExportToExcel(type, fn, dl) {
        var elt = document.getElementById('tbl_exporttable_to_xls');
        var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet1" });
        return dl ?
        XLSX.write(wb, { bookType: type, bookSST: true, type: 'base64' }):
        XLSX.writeFile(wb, fn || ('Resumen Anual ' + this.state.ano + '.' + (type || 'xlsx')));
     }

    getTotal = (datos) => {
        let total = 0;
        datos.datos.forEach(element => {
            total = total + Number(element.monto);
        });
        return total;
    }

    getTotalGeneral = () => {
        let total = 0;
        this.state.dataGeneral.forEach(element => {
            total = total + Number(element.monto)
        });
        return total
    }

    getPorcentaje = (total, parte) => {
        total = Number(total);
        parte = Math.abs(Number(parte));
        if (total !== 0 && parte !== 0) {
            return ((parte/total)*100).toFixed(0)
        }
        return 0
    }
    
    getPorcentajeTotal = (total, parte) => {
        total = Number(total);
        parte = Number(parte);
        if (total !== 0 && parte !== 0) {
            return ((parte/total)*100).toFixed(0)
        }
        return 0
    }

    CenteredMetric = ({ centerX, centerY }) => {
        let total = 0
        total = this.getTotalGeneral();
        return (
            <>                
                <text
                    x={centerX}
                    y={centerY - 15}
                    textAnchor="middle"
                    dominantBaseline="central"
                    style={{
                        fontSize: '24px',
                        fontWeight: 600,
                    }}
                >
                    Balance
                </text>
                <text
                    x={centerX}
                    y={centerY + 15}
                    textAnchor="middle"
                    dominantBaseline="central"
                    style={{
                        fontSize: '24px',
                        fontWeight: 600,
                    }}
                >
                    ${this.numberWithCommas(total)}
                </text>
            </>
        )
    }
    
    render() { 
        return ( 
            <>
                <div className="col-4 py-0 px-1">
                    <div className="inputGroup">
                        <label htmlFor="ano">Año</label>
                        <input type="number" id='ano' step={1} className='form-control inputUnderline text-center text-white' style={{ borderRadius: '0px', fontSize: '16px' }} value={this.state.ano} name="ano" onChange={this.onChangeValue}/>
                    </div>
                </div>
                <div className="col-4 mt-2 py-0 px-1">
                    <button className='w-100 btnAplicar' onClick={this.buscar}>Aplicar</button>
                </div>
            {
                this.state.cargando ? 
                    <div className='d-flex justify-content-center mt-5'>
                        <div className="text-start">
                            <MoonLoader color={colores.primary} size={200}/>
                        </div>
                    </div>
                :
                    <div className='contenido px-3'>
                        <div className="mt-3 mx-auto" style={{ height: '40px', width: '200px' }}>
                            <SwitchSelector
                                forcedSelectedIndex={[{label: "Datos", value: "numeros"},{label: "Gráficos", value: "graficos"}].findIndex(e => e.value === this.state.vista)}
                                onChange={this.cambiarVista}
                                wrapperBorderRadius={5}
                                optionBorderRadius={5}
                                backgroundColor={colores.primary}
                                selectedBackgroundColor={colores.dark}
                                options={[
                                    {label: <i className="bi bi-table text-white"></i>, value: "numeros"},
                                    {label: <i className="bi bi-pie-chart text-white"></i>, value: "graficos"}
                                ]}
                            />
                        </div>
                        {
                            this.state.vista === 'numeros' ?
                                <>                                
                                    <div className="mt-2" style={{ overflowX: 'auto' }}>
                                        <table className='tablaAnual' id='tbl_exporttable_to_xls'>
                                            <thead>
                                                <tr>
                                                    <th className='stickyTH'></th>
                                                    <th colSpan={2}>Enero</th>
                                                    <th colSpan={2}>Febrero</th>
                                                    <th colSpan={2}>Marzo</th>
                                                    <th colSpan={2}>Abril</th>
                                                    <th colSpan={2}>Mayo</th>
                                                    <th colSpan={2}>Junio</th>
                                                    <th colSpan={2}>Julio</th>
                                                    <th colSpan={2}>Agosto</th>
                                                    <th colSpan={2}>Septiembre</th>
                                                    <th colSpan={2}>Octubre</th>
                                                    <th colSpan={2}>Noviembre</th>
                                                    <th colSpan={2}>Diciembre</th>
                                                    <th colSpan={2}>Resumen</th>
                                                </tr>
                                            </thead>
                                                {
                                                    this.state.dataActualizada ?
                                                        <tbody>
                                                        <tr className='bg-success' style={{ color: 'white' }}>
                                                            <td className='sticky bg-success'>Total</td>
                                                            {this.state.dataGeneral.map((e) =>
                                                                [
                                                                    <td className='text-end' key={1}>${this.numberWithCommas(e.monto)}</td>,
                                                                    <td className='text-end' key={2}>{this.getPorcentaje(e.monto, e.monto)}%</td>
                                                                ]
                                                            )}
                                                            <td>${this.numberWithCommas(this.getTotalGeneral())}</td>
                                                            <td>{this.getPorcentaje(this.getTotalGeneral(), this.getTotalGeneral())}%</td>
                                                        </tr>
                                                            {this.state.data.map((e, index) =>
                                                                <tr key={index}>
                                                                    <td className='text-start sticky' style={{ whiteSpace: 'nowrap' }}>{e.nombre}</td>
                                                                    {e.datos.map((eMes, index) =>
                                                                        [
                                                                            <td className='text-end' key={1}>${this.numberWithCommas(eMes.monto)}</td>,
                                                                            <td className='text-end' key={2}>{this.getPorcentaje(this.state.dataGeneral[index].monto, eMes.monto)}%</td>
                                                                        ]
                                                                    )}
                                                                    <td className='text-end'>${this.numberWithCommas(this.getTotal(e))}</td>
                                                                    <td className='text-end'>{this.getPorcentaje(this.getTotalGeneral(), this.getTotal(e))}%</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    :
                                                        <tbody></tbody>
                                                }
                                        </table>
                                    </div>
                                    <div>
                                        <button onClick={() => this.ExportToExcel('xlsx')} className='btn mt-1 mb-4' style={{ padding: '0px', textDecoration: 'underline', fontSize: '14px', color: 'gray' }}>Descargar tabla</button>
                                    </div>
                                </>
                            :
                                <div className="elemento py-3 my-4">
                                    <div className="elemento p-0 d-flex mx-auto" style={{ width: 'fit-content', borderRadius: '100%', backgroundColor: 'white', border: '3px solid #654E7C' }}>
                                        <Pie
                                            innerRadius={0.5}
                                            width={320}
                                            height={320}
                                            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                                            colors={{ scheme: 'pastel2' }}
                                            arcLabel={e => `$${this.numberWithCommas(e.value)}`}
                                            enableArcLinkLabels={false}
                                            tooltip={({ datum: {label, color, value} }) =>
                                                <div className="elemento p-1" style={{ border: '2px solid ' + color }}>
                                                    <p className='m-0'>{label}</p>
                                                    <p className='m-0'>${this.numberWithCommas(value)}</p>
                                                </div>
                                            }
                                            layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', this.CenteredMetric]}
                                            data={this.state.resumen.filter(e => e.value !== 0)}
                                        />
                                    </div>
                                </div>
                        }
                    </div>
            }
            <Toaster/>
            </>
         );
    }
}
 
export default AnualGO;