import React, { createContext,Component } from "react";
import {auth} from '../firebase';
export const MyContext = createContext();

class MyContextProvider extends Component{
    constructor(){
        super();
        this.state = {
            currentUser: '',
            email: '',
            idUsuario: '',
            saved: true,
            nombre: '',
            ruta: '',
            subruta: ''
        }
    }

    componentDidMount = () => {
    auth.onAuthStateChanged( async (user) => {
        if (user === null) {
            this.setState({
                currentUser: user
            })
        }else{
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ operacion: 'selectID', email: user.email })
            };
            const response = await fetch('http://pruebas.argeos.net/php/DB/usuarios.php', requestOptions);
            const data = await response.json();
            if (data.error === 0) {
                if (data.idUsuario === '') {
                    this.setState({
                        currentUser: null,
                    })
                    console.log('Usuario no existe en BD.');
                }else{
                    this.setState({
                        currentUser: user,
                        email: user.email,
                        idUsuario: data.idUsuario,
                        nombre: '_' + data.idUsuario
                    })
                }
            }else{
                this.setState({
                    currentUser: null,
                })
                console.log('Error al obtener ID del usuario.');
            }
        }
      })
    }

    saveChanges = () => {
        this.setState({
            ...this.state,
            saved: true
        })
    }

    cambiarRuta = (ruta) => {
        this.setState({
            ...this.state,
            ruta: ruta
        })
    }
    
    cambiarSubruta = (subruta) => {
        this.setState({
            ...this.state,
            subruta: subruta
        })
    }

    unsaveChanges = () => {
        this.setState({
            ...this.state,
            saved: false
        })
    }

    signup = (email, password) => {
        return auth.createUserWithEmailAndPassword(email, password);
    }
     
    login = (email, password) => {
        return auth.signInWithEmailAndPassword(email, password);
    }
     
    logout = () => auth.signOut();

    render(){
        const contextValue = {
            rootState:this.state,
            saveChanges:this.saveChanges,
            unsaveChanges:this.unsaveChanges,
            cambiarRuta:this.cambiarRuta,
            cambiarSubruta:this.cambiarSubruta,
            signup:this.signup,
            login:this.login,
            logout:this.logout,
        }
        return(
            <MyContext.Provider value={contextValue}>
                {this.props.children}
            </MyContext.Provider>
        )
    }

}

export default MyContextProvider;